<template>
  <div v-if="!this.isLoading" class="d-flex flex-column body-container">
    <div v-if="this.$route.path === '/settings'">
      <div class="pb-1 settings-header">
        Settings
      </div>
      <img class="main-banner" src="../assets/game/main-banner.png">
      <div class="d-flex flex-row justify-content-center mt-1">
        <div class="d-flex flex-column align-items-center justify-content-center text-center user-stats-container m-1">
          <div class="stats-header-text col-10">SEASON POINTS</div>
          <div class="stats-points-text">{{ totalFreePlaySeasonPoints }}</div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center text-center user-stats-container m-1">
          <div class="stats-header-text col-10">ROUNDS ENTERED</div>
          <div class="stats-points-text">{{ userRoundsEntered }}</div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center text-center user-stats-container m-1">
          <div class="stats-header-text col-10">PRIZES WON</div>
          <div class="stats-points-text">{{ prizesWon }}</div>
        </div>
      </div>
      <div class="d-flex flex-column text-center pt-1 m-1">
        <button @click="settingClicked('username')" class="edit-profile-button">
          Change Username
        </button>
      </div>
      <div class="d-flex flex-column text-center mt-2 pb-3 m-1">
        <button @click="settingClicked('notifications')" class="edit-profile-button">
          Notifications
        </button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "UserSettings",
  computed: {
    ...mapState(['userLandingStats', 'isLoading'])
  },
  data() {
    return {
      settingsVisible: true,
    }
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    setUserLandingPageStats() {
      this.userRoundsEntered = this.userLandingStats.userStats.roundsPreviouslyEntered;
      this.totalFreePlaySeasonPoints = this.userLandingStats.userStats.totalFreePlaySeasonPoints;
      this.prizesWon = this.userLandingStats.userStats.prizesWon;
    },
    settingClicked(routeName) {
      this.$router.push(`/settings/${routeName}`);
      this.settingsVisible = false;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    this.setUserLandingPageStats();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 120px;
}

.settings-header {
  font-size: 14px;
  font-weight: 750;
  padding-left: 3px;
  padding-top: 10px;
}

.main-banner {
  width: 100%;
  margin-top: -12px;
}

.user-stats-container {
  background-color: #EF151C;
  color: #FFFFFF;
  height: 110px;
  border-radius: 10px;
  width: 33%;
}

.stats-header-text {
  height: 20px;
  font-weight: 600;
  font-size: 12px;
}

.stats-points-text {
  position: relative;
  top: 15px;
  font-size: 35px;
  font-weight: 700;
}

.edit-profile-button {
  height: 50px;
  border-radius: 10px;
  font-weight: 600;
  border: 0;
  background-color: #2D343B;
  color: #FFFFFF;
}

@media screen and (max-width: 370px) {
  .stats-header-text {
    font-size: 11.5px;
  }
}

</style>
